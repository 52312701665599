<template>
  <section>
    <FetchLoading v-if="pending" />
    <FetchError v-else-if="error" :error="error" />
    <div v-else>
      <Breadcrumbs :items="breadcrumbs" />
      <div class="container my-10">
        <h1 class="h2 mb-10">
          <span class="border-b-5 border-brand-blue-300"
            >Browse course areas</span
          >
        </h1>
        <ul
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-spacing"
        >
          <li v-for="(courseArea, i) in sortedCourseAreas" :key="i">
            <CourseAreaCard
              :course-area="courseArea"
              :count="courseArea.courses"
            >
              <p class="text-xs">
                {{ truncateStr(courseArea.description, 100) }}
              </p>
            </CourseAreaCard>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useCourseAreaStore } from '~/stores/courseArea'

useHead({ title: 'Browse course areas | TAFE NSW' })

const courseAreaStore = useCourseAreaStore()

const {
  pending,
  error,
  data: courseAreas
} = await useAsyncData(() => courseAreaStore.fetchAll())

const breadcrumbs = computed(() => {
  return [{ text: 'Course areas', to: { name: 'course-areas' } }]
})

const sortedCourseAreas = computed(() => {
  return courseAreas.value
    ?.filter((area) => area.id && area.name) // Temporary workaround until CMS has field validation implemented
    ?.sort((a, b) => a.name?.localeCompare(b.name || '') || 0)
})

function truncateStr(str: string | null | undefined, maxLength: number) {
  if (!str) {
    return ''
  }
  return `${str.substring(0, maxLength).trim()}...`
}
</script>
